import React from "react"
import { BVInline, BVProductPicker } from "@tyson-foods/react-component-library"

import Layout from "../../components/layout"

class Product extends React.Component {
  render() {
    return (
      <Layout
        metaTitle="Thick Cut Bacon Slices"
        metaDescription="Hand-trimmed, thick cut & naturally smoked bacon products available from Wright Brand Bacon. Browse all of our products today."
        metaKeywords=""
      >
        <BVProductPicker hashTrigger="writeareview" />
        <article class="page-section with-placeholder ">
          <div class="image">
            <img
              src={
                "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/wright-brand-hickory-smoked-bacon-1920x630.png"
              }
              alt="Wright Brand Hickory Smoked Bacon"
            ></img>
          </div>
          <div class="article-shadow"></div>
          <div class="content"></div>
        </article>
        <div class="page-title-description">
          <div class="title-description-container container">
            <h1>Products</h1>
            <p>
              Hand-trimmed, thick cut and naturally smoked. That’s Bacon the
              Wright Way&trade;.
            </p>
          </div>
        </div>

        <article class="page-section product-listing">
          <section class="product-listing-section">
            <div class="container product-list-container">
              <div class="product-list row">
                <div class="product-item col-sm-6 col-md-4">
                  <div class="image-container">
                    <a href="/products/applewood-smoked/" class="image-link">
                      <img
                        src={
                          "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/products/cropped/applewood.jpg"
                        }
                        alt="Applewood Smoked"
                      />
                    </a>
                  </div>

                  <div class="product-content">
                    <h3>Applewood Smoked Bacon</h3>
                    <p>
                      We smoke our thick-cut Applewood Smoked Bacon as long as
                      needed to...{" "}
                      <a
                        class="hidden-xs full-how-to"
                        href="/products/applewood-smoked/"
                      >
                        More
                      </a>
                    </p>
                  </div>

                  <div class="product-list-ratings">
                    <p>Average Customer Ratings</p>
                    <BVInline
                      className="product-rating"
                      productID="F3AB83D249974DA0B800731B5EC58EB5"
                      redirectUrl="/products/applewood-smoked/?showreviews=1#reviews"
                    />
                    <a
                      class="see-all-ratings"
                      href="/products/applewood-smoked/?showreviews=1#reviews"
                    >
                      See All
                    </a>
                  </div>

                  <div class="product-detail-btn generic-btn arrow hidden-lg hidden-md hidden-sm">
                    <a
                      href="/products/applewood-smoked/"
                      class="product-detail-link"
                    >
                      View Product Detail
                    </a>
                  </div>
                </div>
                <div class="product-item col-sm-6 col-md-4">
                  <div class="image-container">
                    <a href="/products/double-smoked-bacon/" class="image-link">
                      <img
                        src={
                          "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/products/doublesmokedsmall.jpg"
                        }
                        alt="Double Smoked Bacon"
                      />
                    </a>
                  </div>

                  <div class="product-content">
                    <h3>Double Smoked Bacon</h3>
                    <p>
                      For our Double Smoked Bacon, we slowly smoke our
                      thick-cut...{" "}
                      <a
                        class="hidden-xs full-how-to"
                        href="/products/double-smoked-bacon/"
                      >
                        More
                      </a>
                    </p>
                  </div>

                  <div class="product-list-ratings">
                    <p>Average Customer Ratings</p>
                    <BVInline
                      className="product-rating"
                      productID="3C12565C08E44FD29DABF10DFAAE9CC4"
                      redirectUrl="/products/double-smoked-bacon/?showreviews=1#reviews"
                    />
                    <a
                      class="see-all-ratings"
                      href="/products/double-smoked-bacon/?showreviews=1#reviews"
                    >
                      See All
                    </a>
                  </div>

                  <div class="product-detail-btn generic-btn arrow hidden-lg hidden-md hidden-sm">
                    <a
                      href="/products/double-smoked-bacon/"
                      class="product-detail-link"
                    >
                      View Product Detail
                    </a>
                  </div>
                </div>
                <div class="product-item col-sm-6 col-md-4">
                  <div class="image-container">
                    <a href="/products/hickory-smoked/" class="image-link">
                      <img
                        src={
                          "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/products/cropped/hickory.jpg"
                        }
                        alt="Hickory Smoked"
                      />
                    </a>
                  </div>

                  <div class="product-content">
                    <h3>Hickory Smoked Bacon</h3>
                    <p>
                      Our Hickory Smoked Bacon is hand-selected and
                      hand-trimmed, then 100...{" "}
                      <a
                        class="hidden-xs full-how-to"
                        href="/products/hickory-smoked/"
                      >
                        More
                      </a>
                    </p>
                  </div>

                  <div class="product-list-ratings">
                    <p>Average Customer Ratings</p>
                    <BVInline
                      className="product-rating"
                      productID="F09F378338144B1EAFA8A8FC10EC225C"
                      redirectUrl="/products/hickory-smoked/?showreviews=1#reviews"
                    />
                    <a
                      class="see-all-ratings"
                      href="/products/hickory-smoked/?showreviews=1#reviews"
                    >
                      See All
                    </a>
                  </div>

                  <div class="product-detail-btn generic-btn arrow hidden-lg hidden-md hidden-sm">
                    <a
                      href="/products/hickory-smoked/"
                      class="product-detail-link"
                    >
                      View Product Detail
                    </a>
                  </div>
                </div>
                <div class="product-item col-sm-6 col-md-4">
                  <div class="image-container">
                    <a href="/products/maple-flavored/" class="image-link">
                      <img
                        src={
                          "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/products/cropped/maple.jpg"
                        }
                        alt="Maple Flavored"
                      />
                    </a>
                  </div>

                  <div class="product-content">
                    <h3>Maple Flavored Bacon</h3>
                    <p>
                      Our Maple Flavored Bacon is 100% naturally smoked over
                      hickory wood...{" "}
                      <a
                        class="hidden-xs full-how-to"
                        href="/products/maple-flavored/"
                      >
                        More
                      </a>
                    </p>
                  </div>

                  <div class="product-list-ratings">
                    <p>Average Customer Ratings</p>
                    <BVInline
                      className="product-rating"
                      productID="ABC6A2806F1C437B8CB0344B50A590C5"
                      redirectUrl="/products/maple-flavored/?showreviews=1#reviews"
                    />
                    <a
                      class="see-all-ratings"
                      href="/products/maple-flavored/?showreviews=1#reviews"
                    >
                      See All
                    </a>
                  </div>

                  <div class="product-detail-btn generic-btn arrow hidden-lg hidden-md hidden-sm">
                    <a
                      href="/products/maple-flavored/"
                      class="product-detail-link"
                    >
                      View Product Detail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>

        <article class="locator-callout">
          <div class="quick-form-container">
            <div class="product-locator-search">
              <h2>Find Our Products</h2>
              <form data-url="/locator/" action="/locator/" method="get">
                <div class="input-wrapper">
                  <input
                    data-val="true"
                    data-val-regex="Please enter a 5-digit ZIP code"
                    data-val-regex-pattern="^\d{5}"
                    data-val-required="Please enter a 5-digit ZIP code"
                    id="ZIP"
                    maxLength="5"
                    name="ZIP"
                    placeholder="Enter Zip"
                    type="tel"
                    defaultValue=""
                  />
                  <input type="submit" value="Locate Bacon"></input>
                  <span
                    class="field-validation-valid"
                    data-valmsg-for="ZIP"
                    data-valmsg-replace="true"
                  ></span>
                </div>
              </form>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default Product
